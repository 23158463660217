'use strict';
var $ = global.jQuery = require('jquery');
var matchHeight = require('jquery-match-height');
require('slick-carousel');
$('.menu-icon').on('click touch', function(e) {
  $('body').toggleClass('nav-open');
});
var serviceCount = $('.slide-link').length;
var winWidth = $(window).width();

$('#title-slider').slick({
  slidesToShow: 1,
  mobileFirst: true,
  asNavFor: '#content-slider',
  focusOnSelect: true,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: serviceCount,
        adaptiveHeight: false,
        vertical: true,
        draggable: false
      }
    }
  ]
});


$('#content-slider').slick({
  arrows: false,
  adaptiveHeight: true,
  asNavFor: '#title-slider',
  fade: true,
  speed: 200,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        speed: 650
      }
    }
  ]
});

if (window.location.pathname === '/services') {
  if (window.location.search !== '') {
    var active = window.location.search.replace('?', '');
    var slide = $('#title-slider').find('.slide-link[data-service="'+ active +'"]');
    var num = slide.data('slide-fade');
    $('#content-slider').slick('slickGoTo', parseInt(num));
  }
}


// Init Google Map
var icon = {
    url: window.location.protocol + '//' + window.location.host + '/assets/admin/map-icon.png', // url
    scaledSize: new google.maps.Size(56, 85), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(28, 85) // anchor
};
function initMap() {
  var geocoder = new google.maps.Geocoder();
  var address = $('#map').attr('data-address');
  var map = new google.maps.Map(document.getElementById('map'), {
    zoom: 17,
    disableDefaultUI: true,
    styles: [{
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [{
            "saturation": 36
          },
          {
            "color": "#000000"
          },
          {
            "lightness": 40
          }
        ]
      },
      {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [{
            "visibility": "on"
          },
          {
            "color": "#000000"
          },
          {
            "lightness": 16
          }
        ]
      },
      {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 20
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 17
          },
          {
            "weight": 1.2
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "administrative.country",
        "elementType": "all",
        "stylers": [{
          "visibility": "simplified"
        }]
      },
      {
        "featureType": "administrative.country",
        "elementType": "geometry",
        "stylers": [{
          "visibility": "simplified"
        }]
      },
      {
        "featureType": "administrative.country",
        "elementType": "labels.text",
        "stylers": [{
          "visibility": "simplified"
        }]
      },
      {
        "featureType": "administrative.province",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "administrative.locality",
        "elementType": "all",
        "stylers": [{
            "visibility": "simplified"
          },
          {
            "saturation": "-100"
          },
          {
            "lightness": "30"
          }
        ]
      },
      {
        "featureType": "administrative.neighborhood",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [{
            "visibility": "simplified"
          },
          {
            "gamma": "0.00"
          },
          {
            "lightness": "74"
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 20
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "all",
        "stylers": [{
          "lightness": "3"
        }]
      },
      {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 21
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{
          "visibility": "simplified"
        }]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 17
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 29
          },
          {
            "weight": 0.2
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 18
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 16
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 19
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 17
          }
        ]
      }
    ]
  });
  geocoder.geocode({
    'address': address
  }, function(results, status) {
    if (status == 'OK') {
      map.setCenter(results[0].geometry.location);
      var marker = new google.maps.Marker({
        map: map,
        icon: icon,
        position: results[0].geometry.location
      });
    } else {
      console.log('Geocode was not successful for the following reason: ' + status);
    }
  });
}
if ($('#map').length) initMap();


//Debounce
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};


// On Resize
var resizeFn = debounce(function() {
  winWidth = $(window).width();
}, 250);

// window.addEventListener('resize', resizeFn);
